@font-face {
  font-family: 'Neue Bold';
  src: url('./components/assets/fonts/PPNeueMontreal-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'Neue Medium';
  src: url('./components/assets/fonts/PPNeueMontreal-Medium.otf') format('truetype');
}
html,
body {
  overflow-x: hidden;
  font-family: 'Neue Medium';
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
#banner button {
  font-size: 13px !important;
  font-weight: 600;
  line-height: 15px !important;
  letter-spacing: 0em;
  list-style: none;
  text-decoration: none;
  padding: 16px 58px;
  background: #fff;
  border-radius: 50px !important;
  color: #2376BD;
  text-transform: capitalize !important;
  border: none;
}

a:hover {
  color: #0a58ca;
}

.footerlinks .linksEmail i.fa {
  color: white;
  margin-right: 10px;
}

body {
  overflow: hidden;
}

p {
  font-family: 'Neue Medium';
  color: #232323;
  font-size: 15px;
  line-height: 25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Neue Medium';
}

h1 {
  font-family: 'Neue Bold';
  font-size: 41px !important;
  font-weight: 700;
  line-height: 50px !important;
  letter-spacing: 0;
}

h2 {
  font-family: 'Neue Medium';
  font-size: 30px !important;
  font-weight: 800;
  line-height: 40px !important;
  letter-spacing: 0;
}

h6 {
  font-size: 17px !important;
}

button {
  font-size: 13px !important;
  font-weight: 600;
  line-height: 15px !important;
  letter-spacing: 0em;
  list-style: none;
  text-decoration: none;
  padding: 16px 58px;
  background: #fff;
  border-radius: 50px !important;
  color: #2376BD;
  text-transform: capitalize !important;
  border: none;
}

a:focus-visible {
  border: 0 !important;
  outline: unset;
}

a {
  text-decoration: none !important;
}

/* HEADER */
section#top-header .header-top-nav p {
  margin: 0;
  color: #fff;
  padding: 11px 0;
}

.header-top-nav {
  background: linear-gradient(90.13deg, #1D90CF -0.85%, #2D51A4 96.34%);
}

.header-icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* gap: 33px; */
  color: #000;
}

.header-icons div a {
  color: #000;
  text-decoration: unset;
}

.header-icons div select {
  background: #ff10;
  border: 0;
  box-shadow: unset;
}

.header-icons div {
  position: relative;
}

.header-icons a,
.header-icons select,
.header-icons i {
  font-size: 14px;
  line-height: 24px;
}

.header-icons div span {
  position: absolute;
  right: -19px;
  top: -2px;
  color: #ccc;
}

.header-icons div select:focus-visible {
  outline: unset !important;
}

.main-header form.d-flex ::placeholder {
  color: #C7C7C7;
  font-size: 15px;
  line-height: 25px;
}

.main-header form.d-flex input {
  background: #EDEDED;
  padding: 9px 50px;
  box-shadow: unset;
  border: 1px solid #E0E0E0;
  border-radius: 29px;
}

.main-header form {
  position: relative;
}

.main-header form.d-flex button.btn.btn-outline-success {
  position: absolute;
  border: 0;
  background: #ff10;
  padding: 0;
  top: 15px;
  color: #000;
  left: 15px;
}

.main-header {
  padding: 10px 0;
}

.navigation-bars ul li a {
  color: #1F1E1E;
  text-decoration: unset;
  text-transform: capitalize;
  /* font-family: Neue Montreal; */
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.navigation-bars ul li {
  list-style: none;
}

.navigation-bars ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

#banner h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  color: #fff;
  padding-bottom: 24px;
}

#banner {
  background: linear-gradient(90.13deg, #1D90CF -0.85%, #2D51A4 96.34%), linear-gradient(0deg, #B4EAE7, #B4EAE7);
  padding: 50px 0;
}

.navigation-bars {
  padding-bottom: 16px;
}

#categories {
  padding: 50px 0;
}

/* categories-slider-style-start */
.wrapper {
  max-width: 100%;
  margin: 0 auto;
  background-color: #027d88;
  padding: 75px 50px;
}

.slick-slide a {
  color: white;
  font-size: 16px;
  /*font-family:myriad-pro, sans-serif;*/
  font-family: "PT Sans", sans-serif;
}

.slick-slide p {
  line-height: 24px;
}

.slick-slide p:last-of-type {
  margin-bottom: 0;
}

.slick-slide p:first-of-type {
  font-weight: bold;
}

.slick-next:before {

  content: "\f105" !important;
}

.slick-prev:before {

  content: "\f104 " !important;
}

.promotions-carousel img {
  margin: 0 auto;
}

.promotions-carousel.row.slick-initialized.slick-slider {
  text-align: center;
}

section#categories .promotions-carousel a {
  text-decoration: unset;
}

section#categories .promotions-carousel .imgtext h6 {
  color: #000;
  font-size: 12px !important;
}

section#categories .promotions-carousel .imgtext {
  background: #C6E3F3;
  margin: 0px 10px;
  padding: 14px 0;
  border-radius: 10px;
}

section#categories .slick-prev {
  position: absolute;
  left: -26px;
  width: auto;
  top: 50%;
  z-index: 11111;
}

section#categories .slick-next {
  position: absolute;
  right: -65px;
  width: auto;
  top: 50%;
  z-index: 11111;
}

section#categories .slick-next:before {
  opacity: 1;
  background: #ff10 !important;
  /* content: "\f105" !important; */
  font-family: fontawesome;
  font-size: 34px;
  position: unset !important;
  font-weight: 100;
  z-index: 11111;
  color: #C7C7C7;
}

section#categories .slick-prev:before {
  opacity: 1;
  background: #ff10 !important;
  /* content: "\f104 " !important; */
  font-family: fontawesome;
  font-size: 34px;
  position: unset !important;
  font-weight: 100;
  color: #C7C7C7;
}

.imgtext h6 {
  color: #000;
}

.columns.large-4 .imgtext {
  padding: 0px 20px;
}

#categories .columns.small-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

section#main-categories .imgtext a h6 {
  padding-top: 6px;
  margin-top: 0px;
  display: flex
;
  align-items: center;
}
section#main-categories .imgtext .columns.small-3 {
  height: 120px;
}

section#main-categories .imgtext .columns.small-3 img {
  object-fit: contain;
  width: -webkit-fill-available;
  height: 100%;
}

#categories .imgtext .row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #C6E3F3;
  margin: 0px 10px;
  padding: 14px 0;
  border-radius: 10px;
}

section#main-categories h2,
section#categories h2 {
  margin-bottom: 33px;
  margin-top: 33px;
  text-transform: capitalize;
  margin-left: 10px;
}

/* categories-slider-style-end */


/* * MAIN CATEGORIES START */

section#main-categories .imgtext {
  padding: 0 16px;
}

section#main-categories .imgtext .row img {
  width: 100% !important;
  border-radius: 14px;
}

section#main-categories a:hover h6 {
  color: #2d51a4;
  transition: .9s;
}

section#main-categories .promotions-carousel h6 i {
  padding-left: 21px;
}

section#main-categories .promotions-carousel h6 {
  color: #000;
  margin-top: 10px;
}

section#main-categories .promotions-carousel {
  text-align: left;
}

section#main-categories a {
  text-decoration: unset;
  transition: .9s;
}

section#main-categories .slick-prev {
  position: absolute;
  left: 0;
  width: auto;
  top: 50%;
  z-index: 11111;
}

section#main-categories .slick-next {
  position: absolute;
  right: -30px;
  width: auto;
  top: 50%;
  z-index: 11111;
}

section#main-categories h2,
section#categories h2 {
  margin-bottom: 33px;
  margin-top: 33px;
  text-transform: capitalize;
}

section#main-categories .slick-next:before {
  opacity: 1;
  background: #ff10 !important;
  content: "\f105" !important;
  font-family: fontawesome;
  font-size: 34px;
  position: unset !important;
  font-weight: 100;
  z-index: 11111;
  color: #C7C7C7;
}

section#main-categories .slick-prev:before {
  opacity: 1;
  background: #ff10 !important;
  content: "\f104 " !important;
  font-family: fontawesome;
  font-size: 34px;
  position: unset !important;
  font-weight: 100;
  color: #C7C7C7;

}

#main-categories .slick-slide img {
  object-fit: cover;
  display: block;
  width: 100%;
}

section#main-categories .imgtext a h6 i {
  margin-left: 8px;
}

/* #main-categories .columns.small-3 {
  padding: 0 25px;
} */
/* MAIN CATEGORIES END */

/* CALLTO ACTION START */
section#calltoaction .row button:hover {
  background: #000;
}

section#calltoaction .row button {
  background: #329AD4;
  color: #fff;
  transition: .9s;
}

section#calltoaction .row h1 {
  margin-bottom: 22px;
}

section#calltoaction {
  padding: 50px 0px;
}

section#calltoaction .row img:hover {
  transform: scale(0.9);
}

section#calltoaction .row img {
  width: 100%;
  transition: .9s;
}

section#calltoaction .row {
  background: #CFF0FF;
  border-radius: 20px;
  padding: 30px 20px;
}

section#gallery {
  text-align: center;
}

section#gallery h1 {
  margin-bottom: 20px;
}

.tz-gallery img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
}

/* CALLTO ACTION END */


/* ABOUT START */
section#about img:hover {
  filter: brightness(0.5);
}

section#about img {
  width: 100%;
  transition: .9s;
}

section#about h1 {
  text-transform: uppercase;
}

section#about .row button:hover {
  background: #000;
}

section#about .row button {
  background: #329AD4;
  color: #fff;
  transition: .9s;
}

section#about {
  padding: 40px 0px;
}

/* ABOUT END */
/* NEW CALL TO ACTION FOOTER START */
#newcall {
  /* background-image: url(../images/newcall.png); */
  background-repeat: no-repeat;
  background-size: cover;
}

.text-print {
  background: #fff;
  padding: 20px 40px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

section#newcall {
  padding: 160px 0px;
  /* margin: 0 20px; */
}

section#newcall .text-print button {
  background: #329AD4;
  color: #fff;
  transition: .9s;
}

section#newcall .row button:hover {
  background: #000;
}

section#newcall .text-print {
  background: #fff;
  padding: 40px 70px;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  border: 1px solid #CACACA;
  border-radius: 20px;
  background: #F5F5F5;
}

/* NEW CALL TO ACTION FOOTER END */

/* BUSINESSCARD SECTION START */
section.breadcrumbs {
  padding-top: 9px;
}

.breadcrumbs i {
  font-size: 21px;
  color: #8B8B8B;
  margin-top: 0;
  line-height: 0;
  position: relative;
  top: 2px;
}

.breadcrumbs span {
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.015em;
  margin-left: 5px;
}

.bussniessfirst h2 {
  font-weight: 700;
}

.bussniessfirst {
  border: 1px solid #E8E8E8;
  padding: 15px 25px;
  border-radius: 5px;
}

section.businesscard {
  padding: 20px 0px;
}

.catbox h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.015em;
  padding-bottom: 5px;
}

.catbox {
  padding: 26px 0;
}

.catbox button {
  padding: 0;
}

.catbox li {
  list-style: none;
  padding: 0px 0px 2px;
}

.catbox ul {
  padding: 0;
  margin: 0;
}

.catbox a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  color: #000000;
}

.bussniessfirst hr {
  margin: 11px -26px 0px;
  color: inherit;
  border: 0;
  border-top: 1px solid #E8E8E8;
  opacity: unset !important;
  width: 114%;
}

.busnisseccestion {
  /* background-image: url(../images/businesscard/cardbanner.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 196px 13px 23px 20px;
}

.businessbanner .row {
  align-items: center;
}

.businessbanner h1 {
  letter-spacing: 0.015em;
  color: #FFFFFF;
}

#banner-btn a {
  color: #1F1E1E;
  text-decoration: none;
}

#banner-btn button {
  padding: 13px 31px;
  margin-right: 10px;
}

div#banner-btn {
  display: flex;
  justify-content: end;
}

.card-categor {
  padding: 41px 0px 0px 12px;
}

.business-card img {
  width: 100%;
}

.business-card {
  background: #84848426;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.business-card:hover {
  background: #C9C9C921;
  transition: .2s;
}

.business-card p {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #454545;
  padding-bottom: 8px;
  padding-top: 5px;
}

.businesscardrow h3 {
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
}

.card-content {
  padding: 15px 6px;
}

.card-content button {
  padding: 13px 21px;
  background: #1D8DCD;
  color: #fff;
}

.card-content button:hover {
  background: #E0E0E0;
  color: #0a58ca;
}

.card-content a {
  color: #fff;
  text-decoration: none;
}

.card-content button:hover {
  background: #E0E0E0;
}

.card-content button:hover a {
  color: #000 !important;
}

.card-categor h2 {
  padding-bottom: 9px;
}

.carddesigm-row {
  padding: 40px 0px 9px 7px;
}

.explorecard img {
  width: 100%;
}

.explorecardtext p {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.02em;
}

.explorecardtext h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  padding-bottom: 5px;
  color: #000;
}

.explorecardtext {
  padding-top: 15px;
}

.explorecardtext button a {
  text-decoration: none;
  color: #8B8B8B;
}

.explorecardtext button {
  padding: 0;
  margin-top: -2px;
}

.seccardrow {
  padding: 11px 0;
}

.fourthcardrow {
  padding-top: 10px;
}

.businesscardlastsec {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0px;
  margin: 80px 0;
}

.businesscardlastsec h2 {
  line-height: 42px !important;
  letter-spacing: -0.05em;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
  padding: 0 470px;
}

.busbtn {
  text-align: center;
  padding-top: 19px;
}

.busbtn a {
  color: #5DAAD5;
  text-decoration: none;
}

.explorecard .col-lg-4 {
  cursor: pointer;
}

.busbtn button:hover a {
  color: #000;
}

.breadcrumbs a {
  text-decoration: none !important;
}

.card-content h3 {
  font-size: 20px;
}

.businesscard a {
  color: #000;
}

.prod .row {
  grid-row-gap: 20px;
}

/* BUSINESSCARD SECTION END */

/* StANDARD BUSINESS CARD PAGE START */
.catsli {
  padding: 0px 0 24px !important;
}

.standard {
  padding: 30px 0 !important;
}

.standard .promotions-carousel {
  padding-top: 25px;
}

.standardline {
  padding: 12px 25px 0px 25px;
}

#search-bar {
  position: relative;
  padding: 0;
}

section.breadcrumbs {
  padding-top: 9px;
  padding-bottom: 13px;
}

#search-input {
  background: #EDEDED;
  line-height: 26px;
  border-radius: 30px;
  padding: 10px 47px;
  font-size: 1.2rem;
  border: 1px solid #a9a9a9;
  font-size: 15px;
  color: #c7c7c7;
  letter-spacing: 0em;
}

svg#search-icon {
  position: absolute;
  top: 7px;
  left: 19px;
  margin-top: 0.8rem;
}

.sort {
  text-align: right;
}

.sort button {
  background: #EDEDED;
  border: 1px solid #E0E0E0;
}

.sort a {
  color: #1E1E1E;
  text-decoration: none;
  font-size: 15px;
}

.prod {
  padding: 27px 0;
}

.prod-box img {
  width: 100%;
}

.prod-box p {
  font-size: 11.45px;
  font-weight: 400;
  line-height: 17.77px;
  letter-spacing: 0.02em;
}

.prod-box h4 {
  font-size: 18.32px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  padding-bottom: 5px;
  color: #1F1E1E;
}

.prod-box {
  cursor: pointer;
}

.prosecro {
  padding-top: 15px;
}

#search-bar svg {
  position: absolute;
  top: 36%;
  left: 20px;
}

/* StANDARD BUSINESS CARD PAGE END */


/* PRODUCT PAGE START */
.gallery__thumbs img {
  width: 30%;
}

.gallery__hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery__thumbs img {
  width: 15%;
}

.gallery__thumbs a {
  margin: 0 8px 0px 0px;
  text-decoration: none !important;
}

.gallery__thumbs a:hover {
  text-decoration: none !important;
}

.gallery__thumbs a:focus {
  outline: none !important;
}

.gallery__thumbs {
  padding-top: 20px;
}

section.prodcut-info {
  padding-top: 26px;
}

.rating .bi.bi-star-fill {
  color: #FFB21E;
  margin: 0 2px;
}

span.ratin_no {
  font-size: 15px;
  font-weight: 500;
  line-height: 0;
  position: relative;
  top: 4px;
  left: 5px;
}

span.rev {
  color: #909090;
  font-size: 12px;
  position: relative;
  top: 3px;
  left: 8px;
}

.pro-short-discription p {
  font-size: 12px;
  line-height: 18.48px;
  padding: 24px 0 8px;
}

.pro_detail {
  padding-left: 10px;
}

.clientinformation .categories h4{
    font-size: 16px;
}
.clientinformation .categories {
  margin-top: 20px;
}
.clientinformation input {
  padding: 13px 18px;
  border: 0.75px solid #6A6A6A;
  border-radius: 50px;
  width: 100%;
  margin: 0px 0px 0 0px;
  font-size: 12px;
  letter-spacing: 0.015em;
  color: #1F1E1E;
}

.clientinformation input::placeholder {
  font-size: 12px;
  letter-spacing: 0.015em;
  color: #1F1E1E;
  text-transform: capitalize;
}

.price p {
  color: #272727;
  font-weight: 500;
  padding: 15px 6px 10px;
}

.paycard {
  padding-left: 6px;
}

.prod_btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 29px;
}

.prod_btn a {
  width: 100%;
}

.prod_btn a button {
  width: 100%;
  box-shadow: 4px 3px 14.100000381469727px 0px #0000002B;
  padding: 20px;
}

.prod_btn button {
  font-weight: 500;
}

ul.tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

ul.tabs li {
  color: #909090;
  display: inline-block;
  padding: 10px 15px 0 4px;
  cursor: pointer;
}

.current {
  color: #000 !important;
}

.description {
  padding: 30px 0;
}

.tab-content {
  display: none;
  padding: 15px;
}

.tab-content.current {
  display: inherit;
}

.tab-content h1 {
  letter-spacing: 0.015em;
  font-weight: 600;
  font-size: 35px !important;
}

.tab-content li,
.tab-content p {
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #000000;
}

.tab-content img {
  width: 100%;
}

.tab-content ul {
  padding-top: 40px;
}

ul.tabs {
  border-bottom: 2px solid #ADADAD;
  padding-bottom: 24px;
}

.tab-content {
  padding-top: 29px;
}

#related h1 {
  font-weight: 700;
  font-size: 28px !important;
  padding-bottom: 12px;
}

#related img {
  width: 100%;
}

.product-review {
  padding: 60px 0;
}

.rew h3 {
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.rew p {
  font-size: 16px;
  font-weight: 400;
  color: #757474;
  margin: 0 0px 0px 15px;
}

.rew {
  display: flex;
  align-items: center;
}

.description-accuracy ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.description-accuracy li {
  display: flex;
  align-items: center;
  gap: 54px;
  padding: 0px 0 11px 0px;
}

.description-accuracy h4 {
  font-size: 18px;
  font-weight: 500;
}

.description-accuracy {
  padding-top: 15px;
}
.star-rew-txt-str-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.star-rew ul {
  padding: 0;
  list-style: none;
  display: flex;
}

.star-rew span {
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  margin-left: 6px;
}

.star-rew {
  padding: 23px 0;
}

.star-rew p {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #888888;
}

.star-rew h4 {
  font-size: 15px;
  font-weight: 500;
}
.star-rew ul li {
  width: 18px;
}
.star-rew ul li img {
  object-fit: contain;
  width: 100%;
}

#related a {
  color: #000;
}

/* popupp style start */
.model-header h1 {
  letter-spacing: 0.015em;
}

.vertical {
  padding-top: 50px;
}

.model-header h2 {
  padding: 14px 0 12px;
}

.model-header {
  text-align: center;
  color: #000;
}

.model-card {
  background: #E2E2E27A;
  border: 1px solid #D6D6D6;
  padding: 15px;
  border-radius: 6px;
}

modal-content a {
  text-decoration: none;
}

.model-card {
  transition: .4s;
}

.model-card-heading p {
  font-weight: 500;
  color: #000;
  padding: 10px 0 52px;
}

.model-cardprice h3 {
  color: #000;
}

.sqaureinnerwhite {
  background: #fff;
  height: 116px;
  margin: 41px 44px 41px 44px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.portraitinnerwhite {
  background: #fff;
  height: 161px;
  margin: 32px 44px 32px 44px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

modal-content a:hover {
  text-decoration: none;
}

.model-card:hover {
  outline: 1px solid #000;
}

.grey-card {
  background: #D9D9D9;
  padding: 4px;
}

.innerwhite {
  background: #fff;
  height: 117px;
  margin: 41px 22px 41px 22px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.Click-here {
  box-shadow: 4px 3px 14.100000381469727px 0px #0000002B;
  cursor: pointer;
  border-radius: 50px;
  color: #5d5d5d;
  width: 100%;
  text-align: center;
  padding: 15px;
  margin: 0 auto;
  transition: 3s ease-in-out;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
}

.custom-model-main {
  text-align: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* z-index: 1050; */
  -webkit-overflow-scrolling: touch;
  outline: 0;
  opacity: 0;
  -webkit-transition: opacity 0.15s linear, z-index 0.15;
  -o-transition: opacity 0.15s linear, z-index 0.15;
  transition: opacity 0.15s linear, z-index 0.15;
  z-index: -1;
  overflow-x: hidden;
  overflow-y: auto;
}

.model-open {
  z-index: 99999;
  opacity: 1;
  overflow: hidden;
}

.custom-model-inner {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  display: inline-block;
  vertical-align: middle;
  width: 702px;
  margin: 30px auto;
  max-width: 97%;
}

modal-content {
  display: block;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
  text-align: left;
  padding: 72px 83px 60px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
}

.model-open .custom-model-inner {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  position: relative;
  z-index: 999;
}

.model-open .bg-overlay {
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.bg-overlay {
  background: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transition: 0.15s linear;
  -o-transition: 0.15s linear;
  transition: 0.15s linear;
}

.close-btn {
  position: absolute;
  right: 25px;
  top: -5px;
  cursor: pointer;
  z-index: 99;
  font-size: 30px;
  color: #121212;
}

.potbtn button {
  width: 100%;
  background: #1D90CF;
}

.potbtn {
  margin-top: 32px;
}

.potbtn a {
  color: #fff;
  font-size: 15px;
}

.modal-content {
  padding: 2px 102px 10px;
}

.potbtn a button {
  color: #fff;
}

.modal-header {
  border: none;
}

button.btn-close {
  position: absolute;
  right: 27px;
  top: 20px;
}

@media screen and (min-width: 800px) {
  .custom-model-main:before {
    content: "";
    display: inline-block;
    height: auto;
    vertical-align: middle;
    margin-right: -0px;
    height: 100%;
  }
}

@media screen and (max-width: 799px) {
  .custom-model-inner {
    margin-top: 45px;
  }
}

/* popupp style end */
/* PRODUCT PAGE END */

/* Design Review START */
input#mark {
  height: 21px;
  width: 43px;
}

.designreviewcontent h1 {
  font-weight: 700;
  font-size: 32.69px !important;
  color: #000;
}

.designreviewcontent h2 {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #000;
}

.designreviewcontent ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.designreviewcontent li {
  font-size: 18px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0.015em;
  color: #212121;
}

.designreviewcontent {
  padding-left: 15px;
}

.designcomment input {
  border: 0.9px solid #A3A3A3;
  padding: 16px;
  border-radius: 50px;
  margin-top: 30px;
  width: 77%;
}

.commark {
  margin-top: 340px;
}

section.designreview {
  margin: 70px 0;
}

div#frontback {
  background: #F4F4F4;
  padding: 50px 40px 344px;
  position: relative;
}

#frontback .tabbed-module {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 600px;
}

#frontback .tabbed-module nav {
  height: 36px;
  overflow: visible;
  display: block;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

#frontback .tabs-content>div {
  display: none;
}

#frontback .tabs-content>div.selected {
  display: block;
  box-shadow: -4px 4px 19.299999237060547px 0px #00000040;
}

#frontback img {
  /* max-width: 50%; */
  height: auto;
}


#frontback ul.tabs {
  background: #D9D9D9;
  display: flex;
  justify-content: center;
  height: 55px;
  border: none;
  border-radius: 37px;
  padding: 5px 6px;
  gap: 11px;
  margin-top: 23px;
}

#frontback li a {
  font-weight: bold;
  text-decoration: none;
  display: block;
  padding: 8px 3px 8px;
  text-align: center;
  color: #666;
  position: relative;
  font-size: 13.47px;
}

#frontback li button:hover {
  background: #ffffff;
}

#frontback li button {
  padding: 10px 44px 10px 44px;
  border-radius: 57px;
  background: #ffffff;
  color: #000;
}

#frontback li button:hover,
#frontback li button:active {
  background: #D9D9D9;
}

#frontback li.selected {
  background: #ffffff;
}

#frontback ul {
  position: absolute;
  top: 58%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  border: none;
}

#frontback li {
  margin: 0 8px;
}

#frontback li.selected>a {
  color: #666;
  top: 1px;
}

.designreviewmain .tab-content {
  display: block;
  box-shadow: -4px 4px 19.299999237060547px 0px #00000040;
  background: #fff;
  height: 39vh;
  padding: 0px;
}


.commbtin a button {
  color: #fff;
  background: #1D90CF;
  width: 100%;
}

.commbtin {
  margin-top: 15px;
}

@media (max-width: 766px) {
  #frontback .tabs {
    border: 1px solid #dcdcdc;
    border-radius: 3px;
  }

  #frontback .tabs li {
    flex: 1 1 100%;
    margin: 0;
    border: none;
    display: none;
    z-index: 10;
    order: 1;
    border-radius: 0;
  }

  #frontback .tabs li>a {
    text-align: left;
    margin: 1px;
  }

  #frontback .tabs li.selected {
    order: 0;
    display: block;
    padding-right: 30px;
  }

  #frontback .tabs li.selected>a {
    top: 0;
    position: relative;
  }

  #frontback .tabs li.selected>a:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #666 transparent transparent transparent;
    content: "";
    display: block;
    position: absolute;
    right: -30px;
    top: 0;
    margin: 15px;
  }

  #frontback .tabs.active>li {
    display: block;
  }

  #frontback .tabs.active>li:last-of-type {
    border-bottom: 0;
  }

  #frontback .tabs.active>li.selected {
    padding-right: 0;
  }

  #frontback .tabs.active>li.selected>a:after {
    border: none;
  }
}

/* Design Review END */

/* Upload Your Design START */
.uploadingsec .row {
  /* align-items: center; */
}

.upload-button {
  border: 1px solid #D1D1D1;
  height: 70px;
  transition: .2s;
  width: 100% !important;
}

.row.rendering img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  height: 100%;
}
.rendering {
  /* height: 100%; */
  align-items: center;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* width: 450px; */
  /* height: 275px; */
}

.front {
  padding-top: 14px;
}

.desgineditarea {
  padding: 15px 0;
  border: 1px solid #CDCDCD;
}

.uploadingsec h1 {
  font-size: 29.07px !important;
  font-weight: 700;
}

.uploadingsec h5 {
  font-size: 16.46px;
  color: #000;
}

.uploadingsec small {
  font-size: 11.49px !important;
  font-weight: 500;
  line-height: 6.49px;
  color: #343434;
}

.desgineditarea .container {
  width: 46%;
}

.editbox {
  text-align: center;
  gap: 7px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.desgineditarea a {
  text-decoration: none !important;
}

.editbox p {
  margin: 0;
  font-size: 10px;
}

img#uploadedImage1,
img#uploadedImage {
  display: none;
  margin-left: 12px;
  object-fit: contain;
  margin-top: 25px;
}

.upload-button:hover {
  border-color: #000;
}

.editbtn button {
  box-shadow: 3.582423448562622px 3.582423448562622px 3.582423448562622px 0px #0000001A;
}

.editbtn a {
  font-size: 11.64px;
  color: #1E1E1E;
  text-decoration: none;
}

.editbtn a:hover {
  text-decoration: none;
  color: #1E1E1E;
}

.editbtn button {
  box-shadow: 3.582423448562622px 3.582423448562622px 3.582423448562622px 0px #0000001A;
  padding: 20px 60px;
}

.editbtn img {
  margin-right: 5px;
  margin-top: -6px;
}

.editbtn {
  padding-top: 18px;
}

.upload-button label.btn {
  width: 99%;
  padding-top: 23px;
  height: 70px;
}

.prebtn button {
  border: 1px solid #1D90CFD4;
  background: #F4F4F4;
  padding: 15px 0px !important;
  width: 100%;
  color: black;
}
.prebtn-prv-btn {

}

.prewfinishbtn {
  padding-top: 15px;
}

.prebtn img {
  margin-right: 7px;
  margin-top: -3px;
}

.prewfinishbtn a {
  font-size: 13.47px;
  text-decoration: none;
}

.finishbtn a {
  color: #fff;
}

.finishbtn a:hover {
  text-decoration: none;
  color: #fff;
}


.prebtn a {
  color: #1E1E1E;
}

.prebtn a:hover {
  text-decoration: none;
  color: #1E1E1E;
}

.finishbtn button {
  background: #1D90CF;
  padding: 15px 0px !important;
  color: #fff;
  width: 100%;
}

.rendering-card-main-div-sec {
 
  width: 100%; /* Ensure it takes full width */
  height: 400px; /* Set a fixed height for preview area */
  border: 1px solid #ddd;
  position: relative; /* For positioning child elements */
  overflow: hidden; /* Hide any overflow for fitted images */
  border: 1px solid #6771e4;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.rendering-card-bdr-div-sec {
  width: 100%;
  height: 100%;
  position: relative;
  border: dashed 1px #00ad26;

}
.img-preview {
  max-width: none; /* Prevent default max-width restriction */
  max-height: none; /* Prevent default max-height restriction */
  transition: transform 0.3s;
}
.upload-button {
  position: relative;
  margin-top: 9px;
}

.upload-button::after {
  /* content: "\2191";
  position: absolute;
  left: 33.3%;
  top: 52%;
  transform: translateY(-50%);
  font-size: 16px; */
}

/* Upload Your Design END */

/* Your Preferences START */
.Preferencearea {
  padding-left: 20px;
  padding-bottom: 50px;
}

.Preferencearea .designreviewcontent {
  padding-left: 0;
}

.designreviewmain-img-btn-sec-m-div {
  height: 100%;
  background-color: #f4f4f4;
  padding: 30px;
}
.designreviewmain-img-prv-sec {
  box-shadow: -4px 4px 19.299999237060547px 0px #00000040;
  background: #fff;
  height: auto;
  padding: 0px;
}
.designreviewmain-img-prv-sec img {
  object-fit: contain;
  width: 100%;
}
.designreviewmain-btn-chng-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 40px;
  background-color: #d9d9d9;
  padding: 2px;
  width: fit-content;
  border-radius: 50px;
}
.designreviewmain-btn-chng-sec button{
  color: #000;
  padding: 8px 25px;
  background-color: transparent;
  margin: 0;
}
.designreviewmain-btn-chng-sec button.active {
  background-color: white;
}

.ticknessarea h3,
.texturesarea h3,
.cornersarea h3 {
  font-size: 20.6px;
}

.ticknessproductarea .row,
.texturesproarea .row,
.cornersprodarea .row {
  align-items: center;
}

.ticknessproductarea {
  border: 1.03px solid #C3C3C3;
  padding: 15px 10px;
  border-radius: 6px;
  margin-bottom: 15px;
  transition: .2s;
}
.ticknessproductarea button {
  margin: 0;
}

.ticknessproductarea:hover {
  border-color: #000;
}

.ticknessproductarea:hover p {
  color: #1D90CF;
}

.proprce p {
  margin: 0;
  color: #8E8E8E;
  font-size: 16px;
  transition: .2s;
  text-align: end;
}

.proprce {
  text-align: right;
}

.texturesproarea {
  border: 1.03px solid #C3C3C3;
  padding: 15px 10px;
  border-radius: 6px;
  margin-bottom: 15px;
  transition: .2s;
}
.texturesproarea button{
  margin: 0;
}

.texturesproarea:hover p {
  color: #1D90CF;
}

.texturesproarea:hover {
  border-color: #000;
}

.texturesarea {
  padding-top: 20px;
}

.Cornersarea {
  padding-top: 20px;
}

.cornersprodarea h4 {
  text-align: left;
  color: #000;
  font-size: 16px;
}

.cornersprodarea .contentarea p {
  text-align: left;
  margin: 0;
  font-size: 10px;
  line-height: 13.08px;
  letter-spacing: 0.02em;
}

.cornersprodarea {
  border: 1.03px solid #C3C3C3;
  padding: 15px 10px;
  border-radius: 6px;
  transition: .2s;
  margin-bottom: 15px;
}
.cornersprodarea button{
  margin: 0;
}

.cornersprodarea:hover {
  border-color: #000;
}

.cornersprodarea:hover .proprce p {
  color: #1D90CF;
}

section.needhelp {
  box-shadow: 0px 4px 26.899999618530273px 0px #00000040;
  padding: 30px 0;
}

.contactarea {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contactinfo p {
  margin: 0;
  color: #737373;
}

.contactinfo h3 {
  font-size: 16px;
  color: #000;
  margin: 0;
}

.cartprice {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  justify-content: end;
  padding-bottom: 10px;
}

.needhelp .row {
  align-items: center;
}

.barpricingarea p {
  font-size: 11px;
  text-align: right;
}

.barpricingarea-p-main-div {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
}

.qntyarea button {
  border: 1px solid #329AD4;
  background: transparent;
  color: #000;
  padding: 15px !important;
  font-weight: 500;
  width: 125px;
}
.qntyarea button span {
  color: #a1a1a1;
}
.qntyarea {
  width: 50%;
}
.qntyarea button:hover {
  background: #329AD4;
  color: #fff;
  border-color: #329AD4;
}
.cartbtn {
  width: 50%;
}

.cartbtn button {
  border: 1px solid #329AD4;
  background: #329AD4;
  color: #fff;
  padding: 15px !important;
  width: 125px;
  font-weight: 500;
}

.cartbtn button:hover {
  background: transparent;
  color: #000;
  border-color: #329AD4;
}

.qntycartbtn {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cartprice small {
  color: #a1a1a1;
  font-size: 18px;
  text-decoration: line-through;
}

.cartprice h3 {
  margin: 0;
  font-size: 31.49px;
}

/* Your Preferences END */

/* Explore our all Business Cards START */
.exlporeheader .row {
  align-items: center;
}

.exlporeheader h1 {
  font-size: 26px !important;
  font-weight: 700;
}

.helpimgg {
  text-align: right;
}

section.exlporeheader {
  padding-top: 60px;
}

section.continue {
  box-shadow: 0px 4px 26.899999618530273px 0px #00000040;
  padding: 25px 25px;
  margin-top: 70px;
}

.Standardimgbox {
  display: flex;
  align-items: center;
  gap: 22px;
}

.addedtocard p {
  margin: 0;
  color: #000000;
  font-size: 12px;
}

.sinearea {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -4px;
}

.continue .row {
  align-items: center;
}

.continuebox button {
  background: #329AD4;
  color: #fff;
  font-size: 16px !important;
  transition: .2s;
  border: 2px solid #329ad4;
}

.continuebox button:hover {
  background: transparent;
  color: #329AD4;
}

.continuebox {
  text-align: right;
}

.continueteaxt h1 {
  font-size: 25px !important;
  line-height: normal !important;
  margin: 0 0 5px 0;
}

.stanImg {
  background: #D9D9D9;
  padding: 10px;
}
.stanImg img{
  object-fit: contain;
  width: 100%;
}

/* Explore our all Business Cards END */

/* My Cart Page END */
.cartheader h1 {
  font-size: 24px !important;
  font-weight: 900;
}

.greybox {
  background: #C6C6C6;
  box-shadow: 0px 1.5808125734329224px 2.3712189197540283px 0px #0000001A;
  padding: 16px 36px;
}

.cartheader .slick-prev:before,
.slick-next:before {
  color: #000;
  opacity: 999;
  right: 9px;
  position: absolute;
  bottom: 7px;
}
.cartheader .slick-prev,.cartheader .slick-next{
background-color: white;
}
.cartheader .slick-next:before {
  color: #000000 !important;

}

.cartheader .slick-arrow {
  top: 50%;
  border: 1px solid #BEBEBE;
  padding: 20px;
  border-radius: 0 !important;
  border: none;
  z-index: 99;
}

.cartheader .slick-arrow:hover {
  background-color: #fff;
}

#carteditbtn button {
  border: 1px solid #1D8FCE;
  padding: 10px 0px;
  width: 100%;
}

div#carteditbtn {
  text-align: center;
  padding-top: 10px;
}

.cartbodyheader h1 {
  font-size: 21.65px !important;
  font-weight: 700;
}

.cartbodycontentleft {
  display: flex;
  gap: 15px;
  align-items: center;
}

.cartbodycontentleft h1 {
  font-size: 18px !important;
  margin: 0;
}

.cartbodycontentleft select {
  padding: 5px 40px 5px 12px;
  border-radius: 50px;
}

.cartbodycontent .row {
  align-items: center;
}

.cartbodycontent p {
  margin: 0;
}

.cartbodycontent h1 {
  font-size: 15px !important;
  font-weight: 700;
}

.cartbody ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.cartbody ul li {
  padding-bottom: 15px;
  color: #000;
}

.cartbody {
  margin-left: 25px;
}

.alignleft {
  text-align: right;
}

#remove p {
  color: #BC2929;
}

.cartbodyfooter {
  padding-top: 40px;
}

.cartbodyfooter h1,
.cartbodyfooter p {
  font-size: 18px !important;
  font-weight: 600;
  margin: 0;
}

.cartbodyfooter .row {
  align-items: center;
}

.carttotal {
  background: #ECECEC;
  padding: 20px;
  border-radius: 7px;
}

.carttotal h1,
.OrderDetails h1 {
  font-size: 23.58px !important;
  font-weight: 700;
}

.carttotalcontentbottom h1 {
  font-size: 17.01px !important;
}

.carttotalcontent h2 {
  font-size: 13.48px !important;
  color: #1B1B1B;
}

.carttotalcontent .row {
  align-items: center;
}

.payimg img {
  width: 100%;
}

.payimg {
  padding: 40px 0px 20px;
}

.carttotalfooter button {
  background: #1D90CF;
  color: #fff;
  padding: 12px 0;
  width: 100%;
}

/* My Cart Page END */
/* Account Page START */
.accsocialbtn button {
  width: 100%;
  border: 1px solid #838383;
  background: transparent;
  padding: 12px 0;
  transition: .2s;
  margin: 11px 0;
}

h5.acc-title {
  font-size: 20px !important;
}

.account-body {
  padding-top: 22px;
}

.account-body form {
  margin-top: 30px;
}

.account-body .form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: #D0D0D0;
  border-radius: 0;
  padding-bottom: 15px;
  padding-top: 15px;
}
.account-body .otpinputs {
  justify-content: center;
}
.account-body .otpinputs .form-control {
  border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid gray;
    height: 50px;
    width: 50px;
}

.accsocialbtn button a {
  color: #2B2B2B;
  transition: .2s;
}

.accsocialbtn span {
  margin-left: 30px;
}

.accconec {
  border: 1px solid #e5e5e5;
  padding: 50px;
  border-radius: 8px;
}

.account-header h1 {
  font-size: 24px !important;
  text-transform: capitalize;
  text-align: center;
}

.form-check {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.form-check-acc-sec {
  flex-wrap: wrap;
  gap: 10px 0;
}

.form-check label {
  border-bottom: 1px solid;
  color: #444444;
  margin-left: 8px;
}

section.acount,
.dashboardlogin {
  padding-top: 53px;
}

.acc-terms p {
  color: #a9a9a9;
}

.acc-terms {
  text-align: center;
  padding-top: 25px;
}

.accbtn button {
  width: 100%;
  padding: 22px;
}

button.makeacc {
  margin-top: 15px;
  background: #1D90CF;
  box-shadow: 3.4389595985412598px 4.58527946472168px 4.2413835525512695px -2.29263973236084px #00000040;
  border-radius: 10px !important;
  color: #fff;
  font-size: 18.34px !important;
}

button.singin {
  box-shadow: 0px 2px 14px -1px #00000040;
  border-radius: 10px !important;
  color: #1D90CF;
  font-size: 18.34px !important;
}

.acc-terms a {
  color: #1D90CF;
}

.forgot-password a {
  color: #1D90CF;
  border-bottom: 1px solid;
  margin-left: 8px;
}

/* Account Page END */
/* Shipping Detail START */
.shippinform,
.shipping-method-box,
.Deliverymethod,
.payform {
  background: #D3D3D32E;
  padding: 20px;
  border-radius: 7px;
}

.righticon svg {
  position: absolute;
  top: -38px;
  right: 14px;
  transform: rotate(90deg);
}

select#state,
select#country {
  color: #000 !important;
  font-weight: 500;
}

.righticon {
  position: relative;
}

.shippinform .form-group input,
.shippinform .form-group select {
  border: 1px solid #A8A8A8;
  border-radius: 50px;
  padding: 14px 20px;
  margin: 6px 0px;
  font-size: 15px;
  font-style: italic;
  color: #777777 !important;
  background: transparent;
}

.default label {
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
  margin-left: 10px;
}

.default {
  padding-top: 16px;
  padding-left: 16px;
}

.default input {
  padding: 11px;
  margin-bottom: 15px;
}

.savebtn {
  text-align: right;
  padding: 70px 0 10px;
}

.savebtn button {
  background: #1D90CF;
  color: #fff;
}

.method {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.method button {
  width: 100%;
  background: #f7f7f7;
  border-radius: 6px !important;
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 19.66px !important;
  margin-bottom: 10px;
  padding: 21px 22px;
}

.shipping-details {
  padding-top: 50px;
}

.shippinform h2,
.shippingheading h1,
.Deliverymethod h1,
.payform h2 {
  font-size: 19.66px !important;
  font-weight: 700;
}

.Deliverymethod-box1 label {
  margin-left: 10px;
}

.delradio {
  padding-bottom: 20px;
}

.Deliverymethod-box2 p {
  color: #757474;
  text-align: right;
  margin-right: 68px;
}

.Deliverymethod-box .row {
  align-items: center;
}

.Deliverymethod-box3 button {
  background: #1D90CF;
  color: #fff;
}

.Deliverymethod-box3 {
  text-align: right;
}

/* Shipping Detail END */
/* Shipping Method START */
.shippingheading ul {
  list-style: none;
  padding: 0;
}

.shippingheading li {
  color: #000;
  font-size: 15px;
  margin-bottom: 3px;
}

.shippingedit a {
  color: #1D90CF;
}

.shippingedit {
  text-align: right;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 108px;
}

.shipping-method-box .row {
  align-items: flex-start;
}

.shippingedit p {
  padding-right: 66px;
  margin: 0;
}

.shippingedit button {
  border: 1px solid #1D90CF;
  background: transparent;
  color: #1D90CF;
}

.shippingedit {
  text-align: right;
}

.Deliverymethod,
.payform {
  margin-top: 10px;
}

section.ShippingMethod {
  padding: 50px 0px 200px;
}

.payment .delradio {
  padding-bottom: 0;
}

.payform p {
  color: #848484;
  font-size: 12px;
  margin-top: -9px;
}

.payform input {
  width: 100%;
}

.rem input#rememberCard {
  width: 20px;
  height: 20px;
  padding: 0;
  margin-right: 10px;
}

.payform input {
  width: 100%;
  border: 1.19px solid #CFCFCF;
  padding: 15px 15px;
  margin: 0 0px 10px;
  border-radius: 6px;
  background: transparent;
}

.rem {
  padding-top: 10px;
}

.paybtn {
  text-align: right;
}

.paybtn button {
  background: #1D90CF;
  color: #fff;
}

.payment {
  padding-top: 50px;
}

/* Shipping Method END */

/* Thank you Page START  */
.OrderDetails {
  background: #ECECEC;
  border-radius: 7px;
  padding: 12px 18px 70px 17px;
  margin-bottom: 10px;
}

.thankyou {
  padding-top: 50px;
}

.thankyoucontent {
  background: #D3D3D32E;
  padding: 50px 20px 20px;
  border-radius: 7px;
}

.thkimg {
  text-align: center;
}

.thankyoucontent h1 {
  font-size: 32px !important;
  font-weight: 700;
  text-transform: capitalize;
}

.thankyoucontent p {
  color: #0D0D0D;
  /* padding-right: 289px; */
}

.clientDetail h1 {
  font-size: 22.07px !important;
}

.clientDetail {
  background: #329AD421;
  padding: 30px;
}

.clientDetail ul {
  list-style: none;
  padding: 0;
}

.track button {
  background: #329AD4;
  color: #fff;
}

.feedbck button {
  box-shadow: 2px 1px 13px 3px #00000040;
  color: #1E1E1E;
}

.trackorderbtn {
  padding-top: 15px;
}

.trackorderbtn button {
  padding: 20px 0;
  width: 100%;
}

/* Thank you Page END  */

/* DashboardLogin START */
.dashboardlogin input.form-control {
  border: 1px solid #b5b5b5;
  border-radius: 50px;
  padding: 11px;
  margin: 11px 0;
}

.daashboardfirstcol li {
  list-style: none;
}

.daashboardfirstcol li button.nav-link.active {
  background: #1D90CF;
  color: #fff;
}

.daashboardfirstcol ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.daashboardfirstcol li button {
  color: #5D5D5D;
  padding: 12px 31px;
  transition: .2s;
  border-radius: 4px !important;
  width: 100%;
  text-align: left;
}

.daashboardfirstcol li button:hover {
  background: #1D90CF;
  color: #fff;
}

.daashboardfirstcol button.btn.btn-danger {
  background: transparent;
  border: none;
  color: #5D5D5D;
  padding: 0;
}

.acccheader {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #CFCFCF;
}

.acccheader h1 {
  font-size: 24px !important;
  margin: 0;
}

.acccheader h2 {
  color: #747474;
  font-size: 20px !important;
  padding-left: 20px;
  margin: 0;
}

/* account setting START */
.personalinfo p {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  padding-top: 20px;
}

.personalinfo button {
  background: #1D90CF;
  color: #fff;
  padding: 10px 68px;
  font-size: 12px !important;
  margin-top: 10px;
}

.PersonalInfoform input,
.PersonalInfoform select#city,
.paymentcardformation input {
  border: 1.14px solid #AAAAAA;
  width: 100%;
  padding: 14px;
  font-size: 18px;
  color: #878787;
  border-radius: 39px;
  margin-bottom: 15px;
}

.PersonalInfoform {
  padding-top: 62px;
}

.changepassword {
  box-shadow: 0px 12.45px 13.84px 0px #0000000F;
  padding: 30px;
  border: 1.38px solid #D9D9D9;
  border-radius: 10px;
}

.cngpasbtn {
  text-align: end;
}

.cngpasbtn button {
  background: #1D90CF;
  color: #ddd;
}

/* account setting END */
/* Notificationtab START */
.Notificationtab h1 {
  font-size: 24px !important;
  font-weight: 700;
}

.orderconicon img {
  background: #329AD4;
  padding: 20px;
  border-radius: 10px;
}

.OrderConfirmation {
  display: flex;
  gap: 20px;
  padding: 6px 0 25px 4px;
}

.Orderconcontent h3 {
  font-size: 18px;
}

/* Notificationtab END */
/* PaymenMethodtab START */
.paymenmethodtab h2 {
  font-size: 24px !important;
  border-bottom: 2px solid #CFCFCF;
  padding-bottom: 10px;
  margin-bottom: 58px;
}

.paymentcardtab {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 80px;
}

.paymentcardtab img {
  margin-left: 18px;
}

.paymentcardtab input {
  position: absolute;
  top: -17px;
}

.paymentcardtab label {
  position: relative;
  cursor: pointer;
}

.cadefult input[type="checkbox"] {
  width: unset;
  margin-right: 6px;
}

.cadefult {
  padding-left: 18px;
  display: flex;
  justify-content: space-between;
}

.cadefult label {
  color: #9E9E9E;
}

.paymentcardformation {
  margin-top: 25px;
}

.paysavebtn button {
  background: #329AD4;
  color: #fff;
}

/* PaymenMethodtab END */
/* DashboardLogin END */

/* FOOTER START*/
section#footer-main .footerlinks ul li a {
  text-decoration: unset;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}

p.aboutfooter {
  font-size: 13px;
  line-height: 23px;
}

section#footer-main h6 {
  font-size: 12px !important;
}

section#footer-main .footerlinks ul li {
  list-style: none;
}

section#footer-main .footerlinks ul {
  margin: 0;
  padding: 0;
}

section#footer-main .footerlinks h4 {
  font-size: 17px;
  text-transform: uppercase;
  line-height: 24px;
}

section#footer-main .footerlinks {
  color: #000;
  padding: 30px 0px 0 50px;
}

section#footer-main {
  background: #329AD4;
  margin: 50px 20px 0px 20px;
  padding: 40px 0px;
  border-radius: 20px 20px 0px 0;
}

#footer-main ul.footersociallinks li i:hover {
  background: #000;
  color: #fff;
  transition: .9s;
}

#footer-main ul.footersociallinks li i {
  background: #fff;
  width: 50px;
  font-size: 25px;
  text-align: center;
  line-height: 50px;
  border-radius: 30px;
}

#footer-main ul.footersociallinks {
  display: flex;
  gap: 20px;
  padding-top: 30px !important;
}

/* FOOTER END*/

/* login */
.register-bg-img {
  background-image: url(./components/assets/images/bg_img.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-bg-color {
  background-color: #ffff;
  margin: 40px 0;
}


/* order place bg */
.step-image {
  width: 50px;
  /* Adjust size as needed */
  height: auto;
  /* Maintain aspect ratio */
  margin-right: 10px;
  /* Adjust spacing */
}


.standard-card-temp-sort-text-bk {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
}

.standard-card-temp-sort-svg-bk {
  font-size: 20px;
}

.standard-card-temp-sort-text-bk h2 {
  margin: 0;
}

.standard-card-temp-sort-btn {
  background-color: transparent !important;
  border: 1px solid gray !important;
  color: black !important;
  display: flex !important;
  align-items: center !important;
  gap: 20px !important;
}

.sqaureinnerwhite-001 {
  background: #fff;
  height: 116px;
  margin: 80px 0px 1px 44px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-top-left-radius: 20px;
}

.sqaureinnerwhite-002 {
  background: #fff;
  height: 116px;
  margin: 80px 0px 1px 44px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.model-cardprice-bcpd {
  color: rgba(131, 131, 131, 1);
}

/* bch */
.business-card-detail-h-div {
  padding: 14px 0;
  display: flex;
  justify-content: center;
}

.business-card-detail-h {
  display: flex;
  justify-content: space-evenly;
}

.business-card-detail-h-ft {
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
}

.business-card-detail-h-ft span {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.business-card-detail-h-f {
  width: fit-content;
  padding: 0 30px;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 20px;
}

.business-card-detail-h-f span {
  font-size: 16px;
  font-weight: 700;
}

.business-card-detail-h-f p {
  margin: 0;
  font-size: 12px;
}

.business-card-detail-h-tc span {
  font-family: serif;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 50px;
  border: 1px solid;
  border-image: linear-gradient(45deg, rgb(255, 0, 0), rgb(0, 255, 0)) 1;
  color: black;
}

.business-card-detail-h-fw span {
  font-size: 15px;
  font-weight: 800;
}

.business-card-detail-h-fu span {
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
}

.business-card-detail-h-xyz span {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px;
  font-size: 10px;
}

.business-card-detail-h-arr span {
  font-size: 10px;
  font-weight: 600;
}

.business-card-detail-h-dup span {
  font-size: 10px;
  font-weight: 600;
}

.uploadingarea-div-sub-h-btn-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px 0;
}
.uploadingarea-div-sub-h-btn-div .uploadingarea-div-sub-h-btn-div-btn {
  padding: 5px 10px !important;
  background-color: #329ad4;
  color: white;
  margin: 0;
  height: fit-content;
  font-size: 10px !important;
}

.uploadingarea-h2-text {
  font-size: 20px !important;
  font-weight: 600;
}

.uploadingarea p {
  font-size: 12px;
}

.prewfinishbtn-form-input {
  border: none !important;
  border-bottom: 1px solid grey !important;
  padding: 10px !important;
  border-radius: 0 !important;
}

.uploadingarea-main-sec-div {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 14px;
  border-radius: 8px;
}

.uploadingarea-main-sec-div button {
  width: 100%;
}

.uploadingarea-sec1-div-edit-sec {
  display: flex;
  flex-direction: column;
  width: 55px;
  margin: auto;
  /* height: 400px; */
  border: none !important;
  border-bottom: 1px solid grey !important;
  padding: 10px 0 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 14px;
  border-radius: 8px;
}

.uploadingarea-sec1-div-edit-sec-btn {
  padding: 0 !important;
  /* margin: auto; */
  width: 55px;
  height: 40px;
  border-radius: 0 !important;
}

.uploadingarea-sec1-div-edit-sec-btn-icon-div {
  width: 100%;
  background-color: #1cafec !important;
}

.uploadingarea-sec1-div-edit-sec-btn-icon {
  color: white;
}

.card-dsgn-sec3-frnt-bck-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-dsgn-sec3-frnt-div {
  background-color: #d9d9d9;
  border-radius: 5px !important;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-dsgn-sec3-frnt-div:active {
  border: 1px solid #000;
}
.card-dsgn-sec3-frnt-div:hover {
  border: 1px solid #000;
}

.card-dsgn-sec3-frnt {
  Width: 70%;
  margin: 35px 20px;
  height: 60px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-dsgn-sec3-frnt-div p {
  margin: 0;
  text-align: center;
}

.card-ipt-dt-m-div-sec {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0 !important;
}

.card-ipt-dt-m-div-sec-div {
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card-ipt-dt-m-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.card-ipt-dt-m-div h3 {
  margin: 0;
  font-size: 30px;
  font-weight: 500;
}

.card-ipt-dt-m-div h4 {
  margin: 0;
  font-size: 25px;
  font-weight: 500;
}

.card-ipt-dt-m-div-b-ln {
  border: 1px solid black;
  width: 65%;
}

.card-btm-dt-sec-div {
  background-color: #1cafec;
  width: 100%;
  text-align: center;
  padding: 15px 0px 5px 0px;
}

.card-btm-dt-sec-div h4 {
  color: white;
  margin: 0px;
  font-size: 25px;
  font-weight: 500;
}

.uploading-sec-prog-m-div {
  display: flex;
  align-items: center;
}

.uploading-sec-prog-m-div-btn {
  border: none;
  background-color: transparent;
  color: gray;
  width: fit-content;
  padding: 10px;
  font-size: 20px !important;
}

.uploading-sec-prog-m-div-main {
  width: 150px;
}

.uploading-sec-prog-m-div-main .progress-bar {
  background-color: #000;
}

.uploading-sec-btn-sec-main {
  margin-top: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 11px;
  padding: 10px;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.uploadingarea-sec1-btm-div-edit-sec-btn {
  margin: 0;
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: space-between;
  padding: 0;
}

.uploadingarea-sec1-btm-div-edit-sec-btn p {
  font-size: 10px;
  color: grey;
  margin: 0;
}
.uploading-sec-modal-main-div .modal-dialog .modal-content {
  padding: 0 !important;
}
.uploading-sec-modal-main-div .modal-dialog .modal-content .modal-body {
  padding: 0 ;
  padding-right: 10px;
}
.uploading-sec-modal-sec1-div {
  width: 100%;
    height: 100%;
    background-color: #329ad4;
    border-radius: 0 30px 0 0;
    padding: 70px 30px;
}
.uploading-sec-modal-sec1-chkbx-frm-h4 {
  font-size: 25px;
  font-weight: 500;
  color: white;
}
.uploading-sec-modal-sec1-chkbx-frm-ul {
  list-style: none;
    padding: 0;
    color: white;
}
.uploading-sec-modal-sec1-chkbx-frm-span {
  color: white;
    text-decoration: underline;
    font-size: 16px;
}
.uploading-sec-modal-sec1-chkbx-frm {
  color: white;
  padding: 0;
  margin: 15px 0;
  justify-content: flex-start;
}
.uploading-sec-modal-sec1-chkbx-frm  label {
  border-bottom: 0px !important; 
    margin-left: 8px;
    text-decoration: none !important;
    color: white !important;
}
.uploading-sec-modal-sec-2-div {
  margin: 70px 0;
}
.sqaureinnerwhite-0000002 {
  margin-top: 8px;
  box-shadow: none !important;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
} 
.sqaureinnerwhite-0000002 img {
  object-fit: contain;
  width: 100%;
  margin: auto;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.sqaureinnerwhite-0000002-h3 {
  color: #000;
}
.sqaureinnerwhite-0000002-p {
  color: #000;
  padding: 0 !important;
}
.sqaureinnerwhite-0000002-span {
  color: #000;
  display: flex;
  gap: 5px;
}
.sqaureinnerwhite-0000002-h5 {
  color: #1cafec;
}
.color-selector {
  display: flex;
  gap: 10px;
}

.color-option {
  display: inline-block;
  width: 30px; /* Adjust the size of the circle */
  height: 30px; /* Adjust the size of the circle */
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid transparent; /* Transparent border initially */
}

.color-option.selected {
  border-color: #333; /* Color of the border for the selected circle */
}

.color-option.red {
  background-color: red;
}

.color-option.green {
  background-color: green;
}

.color-option.blue {
  background-color: blue;
}

.color-option.yellow {
  background-color: yellow;
}


/*  */
.designcomment-text-p {
  border: 0.9px solid #A3A3A3;
  padding: 16px;
  border-radius: 50px;
  margin-top: 30px;
  width: 77%;
  text-align: center;
}
.designcomment-text-p p{
  margin: 0;
}
.bcd-designreviewmain-m-div .nav-tabs {
  width: fit-content;
  left: 140px !important;
  background-color: #d9d9d9;
  border-radius: 25px;
  padding: 5px 0 !important;
}
.bcd-designreviewmain-m-div .nav-tabs li button {
  border: none !important;
}
.bcd-designreviewmain-m-div .nav-tabs #frontback li button:hover, #frontback li button:active {
  background-color: white;
}
#frontback li button:hover, #frontback li button:active {
  background: #ffffff;
}
#frontback li button {
  padding: 10px 44px 10px 44px;
  border-radius: 57px;
  background: #d9d9d9;
  color: #000;
}

/* order product */


.business-card-ticknessproductarea-btn {
  padding: 0 !important;
  width: 100% !important;
}

.b-c-greybox-cart-img {
  object-fit: contain;
  width: 100%;
}

/* stepper */

.custom-stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.step.active {
  background-color: #007bff;
  color: #fff;
}

.main-line {
  flex: 1;
  height: 2px;
  background-color: #ccc;
}
.custom-stepper {
  width: 50%;
}


.logo-img {
  object-fit: contain;
  width: 80%;
}
.businesscardlastsec-txt-h2 {
  padding: 0 !important;
}

.catslid-div-img {
  object-fit: contain;
  /* width: 100%; */
}
.catslid-div-h6 {
  font-size: 10px !important;
  font-weight: 500;
}
.paycard-img {
  object-fit: contain;
  width: 100%;
}


/* ProductInfo.css */

.gallery__hero {
  position: relative;
  overflow: hidden;
  height: 600px;
}

.gallery-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-out; /* Add transition effect */
}

.gallery__hero span {
  cursor: pointer;
    top: 50% !important;
    background: white;
    padding: 10px 15px;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
}
.gallery-hero-right-arr {
  right: 0;
}

.gallery__thumbs {
  display: flex;
  gap: 10px;
  overflow: auto;
}

.gallery__thumbs .is-active {
  border: 2px solid #1D90CF; /* Example border for active thumbnail */
}

.gallery__thumbs img {
  width: 80px; /* Adjust thumbnail size as needed */
  height: auto;
  cursor: pointer;
}


.designcomment-btn-sec {
  border: 0.9px solid #A3A3A3;
  padding: 16px;
  margin-top: 30px;
  width: 100%;
  font-weight: 400;
  color: black;
}

.footerlogo {
  object-fit: contain;
  width: 100%;
}

/* RESPONSIVE MEDIA QUERY */

@media only screen and (max-width: 1600px) {}

@media only screen and (max-width: 1366px) {
  .businesscardlastsec h2 {
    padding: 0 370px;
  }
}


@media only screen and (max-width: 1280px) {
  .businesscardlastsec h2 {
    padding: 0 220px;
  }

}


@media only screen and (max-width: 1024px) {
  .shippingedit {
    text-align: unset;
    gap: 30px;
  }
}


@media only screen and (max-width: 800px) {

  .paymentcardtab input {
    position: unset;
  }

  .default {
    padding-top: 0px;
    padding-left: 0px;
  }

  .default input {
    width: unset;
    margin-right: 9px;
  }

  .personalinfo {
    display: flex;
    flex-direction: column;
  }

  .Deliverymethod-box .row {
    align-items: unset;
  }

  .businesscardlastsec h2 {
    padding: 0 80px;
  }

  .busnisseccestion {
    padding: 49px 13px 17px 20px;
  }

  #banner-btn button {
    padding: 13px 10px;
  }

  .description-accuracy li {
    gap: 34px;
    padding: 0px 0 9px 0px;
  }

  .modal-content {
    padding: 2px 23px 10px;
  }

  div#frontback {
    padding: 35px 21px 40px;
  }

  .commark {
    margin-top: 40px;
  }


  .designcomment input {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .custom-stepper {
    width: 80%;
  }
  .method-sec-div button {
    font-size: 15px !important;
  }
  .method button {
    font-size: 15px !important;
  }
  .default label {
    font-size: 12px !important;
    margin-left: 0 !important;
  }
  .accconec {
    padding: 50px 25px !important;
  }
  .accsocialbtn button {
    padding: 10px 5px !important;
    width: 100% !important;
  }
  .account-header h1 {
    font-size: 20px !important;
    line-height: 25px !important;
  }
  .slick-prev {
    left: -40px !important;
  }
  .slick-next {
    right: -15px !important;
  }
  section.continue {
    padding: 15px 10px
  }
  .continueteaxt h1 {
    font-size: 20px !important;
    line-height: normal !important;
  }
  .designreviewcontent li {
    font-size: 15px;
  }
  .designreviewcontent h1 {
    font-size: 30px !important;
  }
  .designreviewcontent h2 {
    font-size: 15px !important;
  }
  .horizontalcard .row {
    gap: 38px;
  }

  .prod_btn {
    gap: 0;
  }

  .Click-here {
    padding: 14px;
    font-size: 12px;
  }

  modal-content {
    padding: 48px 39px 60px;
  }

  #banner-btn button {
    margin-right: 0;
  }

  .businessbanner h1 {
    text-align: center;
    padding-bottom: 15px;
  }

  div#banner-btn {
    justify-content: center;
  }

  section#categories .slick-prev {
    left: -14px;
    top: 53px;
  }

  section#categories .slick-next {
    right: -44px;
    top: 53px;
  }

  .main-header form.d-flex button.btn.btn-outline-success {
    top: -4px;
    left: -15px;
  }
  .star-rew-txt-str-div {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .tab-content h1 {
    font-size: 25px !important;
  }
  section#newcall .text-print p {
    font-size: 12px;
    line-height: 22px;
  }

  section#newcall .text-print h1 {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  section#newcall .text-print {
    padding: 10px 10px;
    width: 90%;
  }

  section#newcall {
    padding: 20px 0px;
    margin: 10px 10px;
  }

  section#about h1 br {
    display: none;
  }

  section#about h1 {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  section#gallery h1 {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  body button {
    padding: 14px 30px !important;
    margin-bottom: 20px;
  }

  section#calltoaction h1 {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  section#main-categories .slick-next {
    top: 50%;
    right: -50px;
  }

  section#main-categories .slick-prev {
    top: 50%;
    left: -23px;
  }

  section#main-categories h2,
  section#categories h2 {
    margin-bottom: 13px;
    margin-top: 13px;
    text-transform: capitalize;
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: center;
  }

  #categories {
    padding: 11px 0;
  }

  section#banner img {
    width: 100%;
    margin-top: 20px;
  }

  #banner h1 {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  section#banner {
    padding: 30px 0px;
    text-align: center;
  }

  .col-12-medium {
    width: 100% !important;
  }

  section#top-header .header-top-nav p {
    font-size: 12px;
    padding: 4px 10px;
    line-height: 16px;
  }

  .header-icons {
    justify-content: space-evenly;
    padding-top: 10px;
  }

  .main-header .col-12-medium {
    width: 100%;
    text-align: center;
  }

  .navigation-bars {
    display: none;
  }

  section#footer-main .footerlinks {
    padding: 20px 0;
  }

  section#footer-main {
    margin: 0px 0;
    text-align: center;
  }

  #footer-main ul.footersociallinks {
    justify-content: center;
  }

}


@media only screen and (max-width: 360px) {}
/* Profile dropdown styles */
.profile-icon-container {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  z-index: 1000;
}

.dropdown-menu a, .dropdown-menu button {
  padding: 10px;
  text-align: left;
  text-decoration: none;
  color: black;
  border: none;
  background: none;
  cursor: pointer;
}

.dropdown-menu a:hover, .dropdown-menu button:hover {
  background-color: #f1f1f1;
}

.dropdown-menu button {
  text-align: left;
}
.profile-icon-container .dropdown-menu {
  position: absolute;
  display: block;
}


/* modal css */

.modal.fade.show.error .modal-content {
  padding: inherit;
}

.modal.fade.show.error .modal-content .modal-header {
  justify-content: space-between;
}

.modal.fade.show.error .modal-content .modal-header button.close {
  padding: 0px;
  font-size: 32px !important;
}
.modal.fade.show.error .modal-content .modal-footer button {
  padding: 8px 32px;
}
.dropdown-menu.headerdropdown {
  background: #329AD4E5;
  padding: 12px 0px 12px 16px;
}

.dropdown-menu.headerdropdown a, .dropdown-menu.headerdropdown button {
  color: white;
  width: 100%;
  display: block;
  padding: 7px 0px;
  font-size: 14px;
}
.dropdown-menu.headerdropdown a svg, .dropdown-menu.headerdropdown button svg {
  font-size: 20px;
}
.dropdown-menu.headerdropdown a:hover, .dropdown-menu.headerdropdown button:hover {
  background: transparent;
}
.mainDashboard .card {
  padding: 13px 13px;
  background: #E9F4FA;
  margin-top: 14px;
}
.mainDashboard .card h3 {
  font-size: 16px;
}

.mainDashboard .card .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px 0px;
}

.mainDashboard .card .box h2 {
  font-size: 36px !important;
}
.mainDashboard .card .box button {
  background: transparent;
  border: 2px solid #1D90CF;
  color: #1D90CF;
    padding: 15px 30px;
    width: 100%;
}
.mainDashboard .card .recent_orders {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #C5EAFF3D;
  padding: 19px 20px;
  margin-top: 15px;
}
.mainDashboard .card.recent {
  background: #F6F6F6;
}
.mainDashboard .card .recent_orders .product_image {
  background: #C6C6C6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100px;
  width: 200px;
}

.mainDashboard .card .recent_orders .product_image img {
  width: 100%;
  height: 100%;
}

.mainDashboard .card .recent_orders .productDetailList {width: 100%;}
.mainDashboard .card .recent_orders .productDetailList p {
  margin: inherit;
  font-size: 16px;
  margin-bottom: 2px;
}
.Ordertotal .card {
  background: #F6F6F6;
  padding: 13px 13px;
  background: #ffffff;
  margin-top: 14px;
}

.attribute .box-1{
  background-color: gray;
  width: 85px;
  height: 100px;
    border-radius: 6px;
}
.card.attribute {
  padding: 10px 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.attribute .box-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.attribute .box-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.radioBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  text-align: center;
  height: 100px;
}

.radioBox p {
  margin: auto;
}
.model-card.selected {
  border: 3px solid black;
  background: aliceblue;
}



/* upload design */
.design-edit-area {
  padding: 20px 0;
  background-color: #f8f9fa; /* Light background for contrast */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.edit-box {
  text-align: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.edit-box:hover {
  background-color: #e2e2e2; /* Change background on hover */
}

.uploading-sec {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.upload-button {
  margin-top: 10px;
}

/* .upload-button .btn {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

.uploading-area-sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.change-size-btn {
  background-color: #f0ad4e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

/* .img-preview {
  max-width: 100%; 
  max-height: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  display: block; 
  transition: transform 0.3s;
} */
.rendering-card-bdr-div-sec img{
  /* object-fit: contain; */
  width: 100%;
  /* height: 100%; */
}
.editbtn {
  text-align: center;
  display: flex;
  margin: auto;
  justify-content: center;
  gap: 10px;
}

.editbtn button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: -webkit-fill-available;
}

.prebtn, .finishbtn {
  text-align: center;
}

.prebtn-prv-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

section#main-categories .imgtext a {
  display: flex
;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9; /* Optional: Set background color */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.account-body .otpinputs>div {
  justify-content: space-between;
  width: 100%;
}

.account-body .otpinputs>div input {
  width: 43px !important;
  height: 43px;
  border-radius: 102px;
  border: 2px solid #99a6b1;
}
.photo-editor .controls {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 28px;
}


.photo-editor .controls>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photo-editor .controls>div.buttons {
  flex-direction: row;
  gap: 10px;
}
.photo-editor .controls>div label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.rendering .rendering-card-main-div-sec .rendering-card-bdr-div-sec .canvas-container {
  height: 100%;
}

.rendering .rendering-card-main-div-sec .rendering-card-bdr-div-sec .canvas-container canvas {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
}


.modal-overlay-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-preview {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.preview-images {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.preview-image {
  max-width: 200px;
  max-height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.prebtn.flexiblity {
  display: flex;
  gap: 10px;
}